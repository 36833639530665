import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import ExpertiseImage from "./expertiseImage.jpg"
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';


const useStyles = makeStyles(() => ({
    expertiseData: {
        fontSize: "16px",
        color: "#4a4948",
        margin: "12px 0px 0px 12px",
        lineHeight: "24px",
        textAlign: "justify" 
    }
}))

const ExpertiseSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const reduceImage = useMediaQuery('(max-width:1083px)');



    const expertiseData = [
        {
            label: "Computer Vision: ",
            description: "Unlock the potential of visual data with advanced image and video analysis."
        },
        {
            label: "Natural Language Processing (NLP): ",
            description: "Enhance communication and understanding through sophisticated language models."
        },
        {
            label: "Generative AI: ",
            description: "Create unique content and solutions using state-of-the-art AI technologies."
        },
        {
            label: "Data Analytics: ",
            description: "Unlock the potential of visual data with advanced image and video analysis."
        },
        {
            label: "Machine Learning: ",
            description: "Implement intelligent systems that learn and adapt to your business needs."
        },
        {
            label: "Full Stack Web Applications: ",
            description: "Develop dynamic, responsive, and scalable web applications."
        },
        {
            label: "Full Stack Mobile Applications: ",
            description: "Craft high-performance mobile apps for both iOS and Android platforms."
        },
        {
            label: "Robotic Process Automation (RPA): ",
            description: "Streamline operations with automated workflows that boost efficiency."
        },
    ]

    return (
        <Container maxWidth="lg" style={{
            position: 'relative', padding: '40px 10px', alignItems: "center",
        }}>
            <Box sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#272D4E' }}>
                <Typography variant={isMobile ? "h4" : "h3"} style={{
                    fontFamily: 'Montserrat, sans-serif', color: "rgb(6,74,99)",
                    color: "radial-gradient(circle, rgba(6,74,99,1) 0%, rgba(0,22,36,1) 94%)"
                }}><b>Our Expertise</b></Typography>
            </Box>
            <br />
            <br />
            <Grid container alignItems="flex-start" justifyContent="space-evenly" sx={{ color: '#272D4E' }}>
                <Grid item xs={10} sm={12} md={5} sx={{ textAlign: "center", alignSelf: "center" }}>
                    <img
                        style={{ width: reduceImage ? "300px" : "100%", objectFit: "contain", transition: "1s" }}
                        src={ExpertiseImage}
                    />
                </Grid>
                <Grid item sm={12} md={6} >
                    <Typography style={{
                        fontFamily: 'Montserrat, sans-serif', color: "rgb(6,74,99)",
                        color: "#4a4948", paddingBottom: "8px", textAlign: "justify",
                        fontSize: isMobile? "16px": "1.25rem"
                    }}>Our team of experts leverages the latest advancements in technology to deliver exceptional results across various domains:</Typography>
                    <Grid container sx={{ display: "flex", flexDirection: "column" }}>
                        {
                            expertiseData.map((data, idx) => (
                                <Grid item sx={{ display: "flex"}} key = {idx}>
                                    <DoneAllSharpIcon sx={{ padding: "12px 4px 0px 0px" }} color="secondary" />
                                    <p className={classes.expertiseData} style = {{fontSize: isMobile? "14px": "16px"}}>
                                        <span style={{ fontWeight: "800"}}>{data.label}</span> {data.description}
                                    </p>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ExpertiseSection;