import React from 'react';
import { useEffect } from 'react';
import { Container, Typography, Button, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import headerImage from "./headerImage.jpg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { styled } from '@mui/system';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';


// const CustomButton = styled(Button)({
//   fontFamily: 'Montserrat, sans-serif',
//   color: '#fcead7',
//   borderColor: '#fcead7',
//   '&:hover': {
//     backgroundColor: '#fcead7',
//     borderColor: '#fcead7',
//     color: '#5C4033',
//   }
// });

function HomeSection({ aboutRef }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  const boxPatternStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${headerImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: isMobile ? '61px' : '0px',
    width: '100%',
    maxHeight: '400vh',
    height: '100%',
    zIndex: -1
  };
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []);

  const navigate = useNavigate();

  const handleRedirectToContact = () => {
    navigate(`/contactadvisor`);
  };

  const handleRedirectToContactClient = () => {
    navigate(`/contactclient`);
  };

  return (
    <>
      <div style={boxPatternStyle} ></div>
      <Container maxWidth="lg" style={{ position: 'relative', top: isMobile ? '70px' : '40px', padding: '60px 35px 0px 35px', height: isMobile ? 'fit-content' : 'inherit' }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={12} style={{ textAlign: 'center', display: 'flex', height: '90vh', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h1" gutterBottom sx={{ fontWeight: 600, fontSize: isMobile ? '35px' : '40px', fontFamily: 'Montserrat, sans-serif', background: '#ffffff', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
              Welcome to Rode Venture
            </Typography>
            <Typography variant="subtitle1" color="#ffffff" paragraph textAlign={'center'} mr={2}
              sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: isMobile ? '14px' : '1.3rem' }}>
              Your Partner in AI & IT Innovation
            </Typography>
            <br />
            <br />
            <br />
            {!isMobile ? <br /> : ''}
            <IconButton
              size="large"
              sx={{
                animation: 'bounce 2s infinite',
                '@keyframes bounce': {
                  '0%, 100%': { transform: 'translateY(0)' },
                  '50%': { transform: 'translateY(-20px)' },
                },
                height: '70px',
                width: '70px',
              }}
              onClick={() => scrollToRef(aboutRef)}
            >
              <ArrowCircleDownIcon sx={{ color: '#ffffff', fontSize: 'inherit', height: '60px', width: '60px', }} />
            </IconButton>
          </Grid>
        </Grid>
        {/* </div> */}
      </Container>
    </>
  );
}

export default HomeSection;
