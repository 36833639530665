import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CategoryIcon from '@mui/icons-material/Category';
import { Box, Typography, Grid, Container, Card, CardContent, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import "./services.css";


const ServicesSection = ({ deliverRef }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Container maxWidth="lg" style={{ position: 'relative', padding: '40px 10px', alignItems: "center" }}>
            <Box sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#272D4E' }}>
                <Typography variant={isMobile?"h4":"h3"} style={{fontFamily: 'Montserrat, sans-serif' }}><b>Our Services</b></Typography>
            </Box>
            {/* <Box sx={{ padding: '10px 300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" style={{ color: '#000000', fontFamily: 'Montserrat, sans-serif' }}>We define business processes through ideation, creation, and optimization with a team of exceptional strategists.</Typography>
            </Box> */}
            <br />
            <br />
            <Grid container spacing={4} alignItems="stretch" justifyContent="center" sx={{ color: '#272D4E', paddingBottom: "12px" }}>
                <Grid item xs={10} sm={6} md={6} sx = {{display: "flex", justifyContent: "center"}}>
                    <Card className='card1' sx={{
                        maxWidth: 400, paddingBottom: 1, minHeight: 250, maxHeight: 'fit-content', backgroundColor: "#fbf1fa", textAlign: "center", borderRadius: "8px", boxShadow: 0
                    }}>

                        <AlignVerticalBottomIcon className='icon1' sx={{fontSize: "50px", paddingTop: "12px" }} />
                        <CardContent sx={{ padding: '20px', alignItems: "center" }}>
                            <Typography gutterBottom variant="h5" style={{ color: '#000000', fontFamily: 'Montserrat, sans-serif', fontSize: isMobile? "20px": '24px', textAlign: "center", fontWeight: "500" }} component="div">
                            Product Consulting
                            </Typography>

                            <Typography variant="body1" color="#272D4E" sx={{ fontFamily: 'Montserrat, sans-serif', textAlign: 'justify', fontSize: isMobile? "14px": "20px"}}>
                            Our experienced consultants provide strategic guidance to help you navigate the complexities of technology adoption and integration.
                            </Typography>
                            {/* {showMore ?<> */}
                            <br />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={10} sm={6} md={6} sx = {{display: "flex", justifyContent: "center"}}>
                    <Card className = "card2" sx={{
                        maxWidth: 400, paddingBottom: 1, minHeight: 250, maxHeight: 'fit-content', backgroundColor: "#e7fcf4", textAlign: "center", borderRadius: "8px", boxShadow: 0
                    }}>

                        <HomeRepairServiceIcon className = "icon2" sx={{fontSize: "50px", paddingTop: "12px" }} />
                        <CardContent sx={{ padding: '20px', alignItems: "center" }}>
                            <Typography gutterBottom variant="h5" style={{ color: '#000000', fontFamily: 'Montserrat, sans-serif', fontSize:  isMobile? "20px": '24px', textAlign: "center", fontWeight: "500" }} component="div">
                                Product Development
                            </Typography>

                            <Typography variant="body1" color="#272D4E" sx={{ fontFamily: 'Montserrat, sans-serif', textAlign: 'justify',fontSize: isMobile? "14px": "20px"  }}>
                            We design and develop tailor-made solutions that cater to your unique business requirements, ensuring optimal performance and scalability.
                            </Typography>
                            {/* {showMore ?<> */}
                            <br />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>)
}


export default ServicesSection;


{/* <Grid item xs={10} sm={6} md={4}>
<Card className='card3' sx={{
    maxWidth: 400, paddingBottom: 1, minHeight: 250, maxHeight: 'fit-content', backgroundColor: "#fdf2f1", textAlign: "center", borderRadius: "8px", boxShadow: 0
}}>
    <CategoryIcon className = "icon3" sx={{fontSize: "50px", paddingTop: "12px" }} />
    <CardContent sx={{ padding: '20px', alignItems: "center" }}>
        <Typography gutterBottom variant="h5" style={{ color: '#000000', fontFamily: 'Montserrat, sans-serif', fontSize: '24px', textAlign: "center", fontWeight: "500"}} component="div">
            Product Optimization
        </Typography>

        <Typography variant="body1" color="#272D4E" sx={{ fontFamily: 'Montserrat, sans-serif', textAlign: 'justify'}}>
            We ideate and develop unique software solutions to help businesses meet their exact objectives.
        </Typography>
        {/* {showMore ?<> */}
        // <br />
    // </CardContent>
// </Card>
// </Grid> */}