import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import StackImage from "./stackimage.jpg"

import { react, angular, vue, nextjs, tailwind, html, css, javascript, sass, bootstrap } from "./frontend";

import { aws, elasticSearch, express, firebase, mongoDb, mysql, nodeJs, postgreSql, python, redis } from "./backend";
import { computerVision, dataAnalytics, generativeLlm, machineLearning, Rpa } from "./ai";
import { useEffect, useState } from "react";

const frontend = [
    {
        name: "React",
        image: react,

    },
    // {
    //     name: "AngularJs",
    //     image: angular
    // },
    // {
    //     name: "Vue.js",
    //     image: vue
    // },
    {
        name: "Next.js",
        image: nextjs
    },
    {
        name: "Javascript",
        image: javascript
    },
    {
        name: "Html",
        image: html
    },
    {
        name: "Css",
        image: css
    },
    {
        name: "Sass",
        image: sass
    },
    {
        name: "Bootstrap",
        image: bootstrap
    },
    {
        name: "Tailwind",
        image: tailwind
    },
]
const backend = [
    {
        name: "NodeJS",
        image: nodeJs
    },
    {
        name: "ExpressJS",
        image: express
    },
    {
        name: "Python",
        image: python
    },
    {
        name: "MongoDB",
        image: mongoDb
    },
    {
        name: "MySQL",
        image: mysql
    },
    {
        name: "PostgreSQL",
        image: postgreSql
    },
    {
        name: "Firebase",
        image: firebase
    },
    {
        name: "ElasticSearch",
        image: elasticSearch
    },
    {
        name: "Aws",
        image: aws
    },
    {
        name: "Redis",
        image: redis
    }
]
const ai = [
    {
        name: "Computer Vision",
        image: computerVision
    },
    {
        name: "Data Analytics",
        image: dataAnalytics
    },
    {
        name: "Generative LLM",
        image: generativeLlm
    },
    {
        name: "Machine Learning",
        image: machineLearning
    },
    // {
    //     name: "RPA",
    //     image: Rpa
    // }
]

const TechStackSection = () => {
    const theme = useTheme();
    const hideImage = useMediaQuery('(max-width:1070px)');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showItem, setShowItem] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 850) {
                setShowItem(true);
            } else setShowItem(false)
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <Container maxWidth="lg" style={{
            position: 'relative', padding: '40px 10px', alignItems: "center",
            //             background: "rgb(13,152,203)",
            // background: "linear-gradient(90deg, rgba(13,152,203,1) 29%, rgba(3,49,78,1) 94%)"
        }}>
            <Box sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "#272D4E" }}>
                <Typography variant={isMobile ? "h4" : "h3"} style={{
                    fontFamily: 'Montserrat, sans-serif', color: "rgb(6,74,99)",
                    color: "radial-gradient(circle, rgba(6,74,99,1) 0%, rgba(0,22,36,1) 94%)"
                }}><b>Our Tech Stack</b></Typography>
            </Box>
            <br />
            <br />
            <Grid container alignItems="flex-start" justifyContent="center" sx={{ color: '#272D4E' }}>
                <Grid item className="webDevelopment" sm={12} md={7} sx={{ transition: "2s", opacity: showItem ? "1" : "0" }}>
                    <Grid item sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', }}>
                        <Box sx={{ padding: '10px  0px', margin: "0px 0px 12px 0px" }}>
                            <Typography variant="h5" style={{ color: '#000000', fontFamily: 'Montserrat, sans-serif', paddingLeft: "8px" }}><b>Frontend Development</b></Typography>
                        </Box>
                        <Grid container alignItems="flex-start">
                            {
                                frontend.map((stack, idx) => (
                                    <Grid item
                                        key={idx}
                                        sx={{ padding: "19.2px", textAlign: "center", height: "fit-content" }}
                                    >
                                        <img
                                            style={{ height: "25px", width: "auto" }}
                                            src={stack.image}
                                        />
                                        <p style={{ fontSize: "0.9rem", opacity: "0.75" }}>
                                            {stack.name}
                                        </p>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
                        <Box sx={{ padding: '10px  0px', margin: "0px 0px 12px 0px" }}>
                            <Typography variant="h5" style={{ color: '#000000', fontFamily: 'Montserrat, sans-serif', paddingLeft: "8px" }}><b>Backend Development</b></Typography>
                        </Box>
                        <Grid container alignItems="flex-start">
                            {
                                backend.map((stack, idx) => (
                                    <Grid item
                                        key={idx}
                                        sx={{ padding: "19.2px", textAlign: "center", height: "fit-content" }}
                                    >
                                        <img
                                            style={{ height: "25px", width: "auto" }}
                                            src={stack.image}
                                        />
                                        <p style={{ fontSize: "0.9rem", opacity: "0.75" }}>
                                            {stack.name}
                                        </p>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
                        <Box sx={{ padding: '10px  0px', margin: "0px 0px 12px 0px" }}>
                            <Typography variant="h5" style={{ color: '#000000', fontFamily: 'Montserrat, sans-serif', paddingLeft: "8px" }}><b>Artificial Intelligence</b></Typography>
                        </Box>
                        <Grid container alignItems="flex-start">
                            {
                                ai.map((stack, idx) => (
                                    <Grid item
                                        key={idx}
                                        sx={{ padding: "19.2px", textAlign: "center", height: "fit-content" }}
                                    >
                                        <img
                                            style={{ height: "35px", width: "auto" }}
                                            src={stack.image}
                                        />
                                        <p style={{ fontSize: "0.9rem", opacity: "0.75" }}>
                                            {stack.name}
                                        </p>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>

                {hideImage ? null : <Grid item xs={10} sm={12} md={4} sx={{ textAlign: "center", alignSelf: "center" }}>
                    <img
                        style={{ height: "450px", objectFit: "contain" }}
                        src={StackImage}
                    />
                </Grid>}
            </Grid>
        </Container>
    )
}

export default TechStackSection;