import './App.css';
import React, { useRef, useState } from 'react';
import Navbar from './navabar/navabar';

import HomeSection from './home/home';
import Aboutsection from './about/about';

import ServicesSection from "./services/services"
import Contact from './contact/contact';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import TechStackSection from './techStack/techStack';
import ExpertiseSection from './expertise/expertise';
import ChooseSection from './whyChoose/whyChoose';

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const expertiseRef = useRef(null);
  const chooseRef = useRef(null);
  const stackRef = useRef(null);
  const serviceRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar homeRef={homeRef} aboutRef={aboutRef} stackRef={stackRef} contactRef={contactRef} />
                <div ref={homeRef}><HomeSection aboutRef={aboutRef} contactRef={contactRef}/></div>
                <div ref={aboutRef} style={{ width: '100%', height: '0px' }}></div>
                <br />
                <Aboutsection />
                <div ref={expertiseRef} style={{ width: '100%', height: '10px' }}></div>
                <br />
                <ExpertiseSection />
                <div ref={serviceRef} style={{ width: '100%', height: '10px' }}></div>
                <br />
                <ServicesSection />
                <div ref={chooseRef} style={{ width: '100%', height: '10px' }}></div>
                <br />
                <ChooseSection />
                <div ref={stackRef} style={{ width: '100%', height: '10px' }}></div>
                <br />
                <TechStackSection />
                <div ref={contactRef} style={{ width: '100%', height: '10px' }}></div>
                <br />
                <Contact id="contact"/>
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;



// import  { React, useRef }  from "react";
// import { Routes, Route, Navigate  } from "react-router-dom";
// import './App.css';
// import routes from './route.js';
// import Mainpage from './mainpage'
// import Navbar from './navabar/navabar';
// import HomeSection from './home/home';
// import Aboutsection from './about/about';
// import OfferSection from './offer/offer';
// import Contact from './contact/contact';


// export default function App() {
//   const homeRef = useRef(null);
//   const aboutRef = useRef(null);
//   const offerRef = useRef(null);
//   const clientRef = useRef(null);
//   const contactRef = useRef(null);


//   // const { pathname } = useLocation();
//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }
//       if (route.route) {
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }

//       return null;
//     });
//   return (
//     <div className="App">
//       <Routes>
//       {getRoutes(routes)}
//         {/* <Route path="/bot/1/" component={< Chatbot/>} /> */}
//         <Route path="/" component={<>
//           <Navbar homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef}/>
//           <div ref={homeRef}><HomeSection contactRef={contactRef}/></div>
//           <div ref={aboutRef} style={{width:'100%',height:'10px'}}></div>
//           <br/>
//           <Aboutsection />
//           <div ref={offerRef}style={{width:'100%', height:'10px'}}></div>
//           <br/>
//           <OfferSection />
//           <div ref={contactRef}style={{width:'100%', height:'10px'}}></div>
//           <br/>
//           <Contact id="contact"  contactRef={contactRef}/>
//         </>} />
//         <Route path="/contact" component={<>
//           <Navbar homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef}/>
//           <div ref={contactRef}style={{width:'100%', height:'10px'}}></div>
//           <br/>
//           <Contact id="contact"  contactRef={contactRef}/>
//         </>} />
//         {/* <Route path="*" element={<Navigate to="*" />} /> */}
//       </Routes>
//     </div>
//   );
// }



