import { Box, Card, CardContent, CardMedia, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import "./whyChoose.css";
import innovativeSolution from './images/innovativeSolution.jpg';
import expertTeam from "./images/expertTeam.jpg";
import customerCentric from "./images/customerCentrix.jpg";
import provenTrackRecord from "./images/provenTrackRecord.jpg";



const useStyles = makeStyles(() => ({
    expertiseData: {
        fontSize: "16px",
        color: "#4a4948",
        margin: "12px 0px 0px 12px",
        lineHeight: "24px"
    }
}))

const ChooseSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const reduceImage = useMediaQuery('(max-width:1083px)');



    const expertiseData = [
        {
            label: "Innovative Solutions",
            image: innovativeSolution,
            description: "We harness the power of AI and IT to create groundbreaking solutions that give you a competitive edge."
        },
        {
            label: "Expert Team",
            image: expertTeam,
            description: "Our team comprises industry veterans and tech enthusiasts who are passionate about technology and its potential."
        },
        {
            label: "Customer-Centric Approach",
            image: customerCentric,
            description: "We prioritize your needs and work closely with you to deliver solutions that exceed expectations."
        },
        {
            label: "Proven Track Record",
            image: provenTrackRecord,
            description: "With numerous successful projects under our belt, we have a reputation for excellence and reliability."
        }
    ];

    return (
        <Container maxWidth="lg" style={{
            position: 'relative', padding: '40px 10px', alignItems: "center",
        }}>
            <Box sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#272D4E' }}>
                <Typography variant={isMobile ? "h4" : "h3"} style={{
                    fontFamily: 'Montserrat, sans-serif',
                }}><b>Why Choose Rode Venture?</b></Typography>
            </Box>
            <br />
            <br />
            <Grid container spacing={3} alignItems="stretch" justifyContent="center" sx={{ color: '#272D4E' }}>
                {
                    expertiseData.map((data, idx) => (
                        <Grid item xs={12} sm={6} md={6} sx = {{paddingBottom: "35px", display: "flex", justifyContent: "center"}} key = {idx}>
                            <Card className="card" sx={{
                                maxWidth: 500, paddingBottom: 1, minHeight: 300, maxHeight: 'fit-content', borderRadius: "5%"
                            }}>
                                <CardMedia
                                    className = "cardMedia"
                                    sx={{ height: 200, objectFit: "cover" }}
                                    image={data.image}
                                    title="green iguana"
                                />
                                <CardContent sx={{ padding: '20px', zIndex: "50" }}>
                                    <Typography gutterBottom variant="h5" style={{ color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontSize: '27px', fontWeight: "600", textShadow: "1px 1px #bab9b6" }} component="div">
                                        {data.label}
                                    </Typography>
                                    <br />
                                    <Typography variant="body1" color="#ffffff" sx={{ fontFamily: 'Montserrat, sans-serif', textAlign: 'justify' }}>
                                        {data.description}
                                    </Typography>

                                    <br />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    )
}

export default ChooseSection;