import React, { useEffect } from 'react';
import { useState } from 'react';
import { AppBar, Toolbar, Button, Box, useTheme, useMediaQuery, Menu, MenuItem } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Turn as Hamburger } from 'hamburger-react';
import { useNavigate } from 'react-router-dom';

import LogoWhite from "./RodeVentureWhite.png";
import LogoBlue from "./RodeVentureBlue.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navbarDisplayFlex: {
    display: 'flex',
    justifyContent: 'center',
    // alignItems: "center"
  },
  navbarDisplayFlex1: {
    color: "transparent",
    display: 'flex',
    justifyContent: 'space-between',
  },
  navLink: {
    textDecoration: 'none',
  },
  menuPaper: {
    width: '99%',
  },
  menuItem: {
    justifyContent: 'center',
  },
  imagelogo: {
    position: 'absolute',
    left: '120px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'space-around'
  },
  imagelogo1: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'space-around'
  },
}));

const Navbar = ({ homeRef, aboutRef, stackRef, contactRef }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const [isOpen, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    console.log("Menu opened");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log("Menu closed")
    // scrollToRef(ref)
    setOpen(false)
    setAnchorEl(null);
  };

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  const navigate = useNavigate();

  const handleRedirectToHome = () => {
    navigate('/');
  };
  const handleRedirectToContact = () => {
    navigate(`/contact`);
  };
  return (
    <div className={classes.root}>
      <AppBar position="fixed" sx={isScrolled ? { bgcolor: "#ffffff", justifyContent: "center", transition: "300ms" } : { bgcolor: "transparent", boxShadow: 0, transition: "300ms" }}>
        <Toolbar className={isMobile ? classes.navbarDisplayFlex1 : classes.navbarDisplayFlex}>
          <Box className={isMobile ? classes.imagelogo1 : classes.imagelogo} onClick={handleRedirectToHome}>
            <img src={isScrolled || isMobile? LogoBlue: LogoWhite} alt="ROD logo" width={isMobile? "80%":'90%'} height='50px' style={{ cursor: 'pointer', paddingRight: "8px"}}></img>
          </Box>
          {isMobile ? (
            <>
              <Button style={{}} onClick={handleMenu}>
                <Hamburger toggled={isOpen} toggle={setOpen} color="#5C4033" size={20} />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  className: classes.menuPaper, // Add this line to apply width style
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ fontFamily: 'Montserrat, sans-serif' }}
              >
                <MenuItem onClick={() => { scrollToRef(homeRef); handleClose() }}>Home</MenuItem>
                <MenuItem onClick={() => { scrollToRef(aboutRef); handleClose() }}>About</MenuItem>
                <MenuItem onClick={() => { scrollToRef(stackRef); handleClose() }}>Stack</MenuItem>
                <MenuItem onClick={() => { scrollToRef(contactRef); handleClose() }}>Contact</MenuItem>
              </Menu>
            </>
          ) : (
            <nav style={{ width: '480px', display: 'flex', justifyContent: 'space-evenly' }}>
              <Button className={classes.navLink} style={{ color:  isScrolled? "#000000": "#ffffff", fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold'  }} onClick={() => scrollToRef(homeRef)}> Home </Button>
              <Button className={classes.navLink} style={{ color:  isScrolled? "#000000": "#ffffff", fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold'  }} onClick={() => scrollToRef(aboutRef)}> About </Button>
              <Button className={classes.navLink} style={{ color:  isScrolled? "#000000": "#ffffff", fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold'  }} onClick={() => scrollToRef(stackRef)}> Stack </Button>
              <Button className={classes.navLink} style={{ color:  isScrolled? "#000000": "#ffffff", fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold'  }} onClick={() => scrollToRef(contactRef)}> Contact </Button>
            </nav>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;