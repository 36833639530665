import React from 'react';
import { useEffect } from 'react';
import { Link, Container, Typography, Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

// import Logo from "./rodEventureWhite.png";
import Logo from "./RodeVentureWhite.png"

import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const useStyles = makeStyles((theme) => ({
  TextField: {
    backgroundColor: 'white',
    '& .MuiInputBase-input::placeholder': {
      color: '#5C4033 !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#5C4033',
      },
      '&:hover fieldset': {
        borderColor: '#5C4033',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#5C4033',
      },
      '& input::placeholder': {
        color: '#5C4033',
      },
    },
  },
  lastfooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  lastfooter1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  iconStyle: {
    objectFit: "contain",
    color: "#d6d4d4",
    cursor: "pointer",
    // padding: "0px 20px 0px 8px",
    position: "relative",
    "&:hover": {
      transition: "100ms",
      color: "#ffffff"
    }
  },
  logoStyle: {
    height:  "50px",
    objectFit: "contain",
  }
}));

const Contact = () => {
  const classes = useStyles();
  const theme = useTheme();

  const currentYear = new Date().getFullYear();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // const scrollToRef = (ref) => {
  //   window.scrollTo({
  //     top: ref.current.offsetTop,
  //     behavior: 'smooth'
  //   });
  // };



  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);


  return (
    <div style={{ position: 'relative', py: '20px' }}>
      <Box sx={{
        background: "rgb(6,74,99)",
        background: "radial-gradient(circle, rgba(6,74,99,1) 0%, rgba(0,22,36,1) 94%)",
        color: 'white',
        // boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.1)', 
        textAlign: 'center',
        padding: '60px 0px 0px 0px',
        marginTop: "20px"
        // borderTop: "1px solid black"
      }}>
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" alignItems="flex-start" >
            <Grid item xs={12} sm={3} md={2} pb={4} pl={2} justifyContent="center">
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                <img src={Logo} className={classes.logoStyle} alt="Rod logo"></img>
                <br />
                <Grid item sx={{ display: "flex", justifyContent: "space-around", height: "45px", alignItems: "center", width: isMobile?"80%": "100%" }}>
                  <XIcon className={classes.iconStyle} sx={{ fontSize: "25px" }} />
                  <FacebookIcon className={classes.iconStyle} sx={{ fontSize: "25px" }} />
                  <LinkedInIcon className={classes.iconStyle} sx={{ fontSize: "25px" }} />
                  <YouTubeIcon className={classes.iconStyle} sx={{ fontSize: "25px" }} />
                </Grid>
              </Box>

            </Grid>
            <Grid item xs={12} sm={5} md={4} pb={4} pl={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                <Typography variant="h5" color="#d6d4d4" pb={1} fontWeight={600} sx={{ fontFamily: 'Montserrat, sans-serif' }}>Let's Venture Together</Typography>
                <Typography variant="subtitle1" color="#d6d4d4" align="left" mb={1} sx={{ fontFamily: 'Montserrat, sans-serif', textAlign: "justify" }}>
                  Join us on a journey of innovation and growth. Whether you're looking to enhance your existing systems or develop new solutions from scratch, Road Venture is here to help.
                </Typography>
                <Typography variant="subtitle1" color="#d6d4d4" align="left" mb={1} sx={{ fontFamily: 'Montserrat, sans-serif', textAlign: "justify" }}>
                  Contact Us today to discover how we can drive your business forward with cutting-edge AI and IT services.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3} pb={4} pl={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <Typography variant="h5" color="#d6d4d4" align="center" pb={1} fontWeight={600} sx={{ fontFamily: 'Montserrat, sans-serif' }}>Contact Us</Typography>
                <Typography variant="h6" color="#d6d4d4" align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>Address</Typography>
                <Typography variant="subtitle1" color="#d6d4d4" align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>Sweden Head  Office : </Typography>
                <Typography variant="subtitle1" color="#d6d4d4" align="left" mb={1} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                  Banergränden 31, 226 48 Lund</Typography>
                <Typography variant="h6" color="#d6d4d4" align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>Telephone</Typography>
                <Link href="tel:+918004006979" underline="none">
                  <Typography variant="subtitle1" color="#d6d4d4" align="center" mb={1} sx={{ fontFamily: 'Montserrat, sans-serif' }}>+46768855541</Typography>
                </Link>
                <Typography variant="h6" color="#d6d4d4" align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>Email</Typography>
                <Link href="mailto:contact@rathresearch.com" underline="none">
                  <Typography variant="subtitle1" color="#d6d4d4" align="center" mb={1} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                    vivek@rodeventure.com
                  </Typography>
                </Link>
              </Box>
            </Grid>


          </Grid>
        </Container>
        <Box component="footer" py={3} sx={{ backgroundColor: 'rgba(0,0,0,0.2)' }} >
          <Box px={3} className={isMobile ? classes.lastfooter1 : classes.lastfooter}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body2" color="lightgrey" align="center"
                  sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                  Copyright © Rodeventure {currentYear}. All Rights Reserved.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Contact;