import React from 'react';
import { useEffect } from 'react';
import { Container, Typography, Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import AIImage from "./Bot.jpg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import './about.css'
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
  imageStyle: {
    width: "100%",
    objectFit: "contain",
    // alignSelf: "center"
  },
  imageStyleOnMoilbe: {
    width: "50%",
    objectFit: "contain",
    justifySelf: "center"
  },
  headingStyle: {
    background: "rgb(58,23,81)",
    background: "linear-gradient(90deg, rgba(58,23,81,1) 29%, rgba(1,55,117,1) 94%)"
  }
}))

function Aboutsection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []);

  return (
    <Container maxWidth="lg" style={{ position: 'relative', padding: '40px 10px', alignItems: "center", marginTop: "40px" }}>
      <br />
      <br />
      <br />
      <Grid container spacing={3} alignItems="stretch" justifyContent="center" sx={{ color: '#272D4E' }}>
        <Grid item xs={12} sm={12} md={7}>
          <Box sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
            <Typography variant="h3" style={{ fontFamily: 'Montserrat, sans-serif', color: "rgb(58,23,81)", color: "linear-gradient(90deg, rgba(58,23,81,1) 29%, rgba(1,55,117,1) 94%)" }}><b>Who we are</b></Typography>
          </Box>
          <br />
          <Box sx={{ padding: '10px  0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography style={{ color: '#4a4948', fontFamily: 'Montserrat, sans-serif', textAlign: "justify", fontSize: isMobile ? "14px" : "1.25rem", lineHeight: "30px" }}>
              At Rode Venture, we are dedicated to transforming your vision into reality through cutting-edge AI and IT solutions. As pioneers in the tech industry, we specialize in providing bespoke consulting services and customized product development. Our mission is to empower businesses with innovative technology that drives success and growth.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} alignContent="center" sx={{ display: "flex", justifyContent: "center" }}>
          <img
            className={isMobile ? classes.imageStyleOnMoilbe : classes.imageStyle}
            src={AIImage}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Aboutsection;